/**======================================================================
=========================================================================
Template Name: Able Pro Admin Template
Author: Phoenixcoded
supports: https://phoenixcoded.ticksy.com
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   authentication, Maintenance, Maps, Landing page messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */


// Theme Font
@import "themes/font/theme-font";

// theme scrollbar
@import "themes/plugin/perfect-scrollbar";

// main framework
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "settings/custom-variables";
@import "settings/theme-variables";

// ng module
@import "react-module/react-module";

// bootstrap
@import "~bootstrap/scss/bootstrap";


// icon
@import "themes/font/next-icon";
@import "themes/font/feather";
@import "themes/font/fontawesome";
@import "themes/font/flaticon";
@import "themes/font/cryptofont";

// bootstrap css
@import "themes/bootstrap-overlay/card";
@import "themes/bootstrap-overlay/button";
@import "themes/bootstrap-overlay/radiobox-checkbox";
@import "themes/bootstrap-overlay/switches";
@import "themes/bootstrap-overlay/badge";

@import "themes/general";
@import "themes/generic";
@import "themes/plugin/animate";


// theme layouts
@import "themes/layouts/menu-lite";
@import "themes/layouts/menu-react";
@import "layout-dark";
@import "layout-rtl";

// Dashboard Widgets
@import "themes/dashboard/widget";

// custom plugin
@import "themes/plugin/sweatalert";
@import "themes/plugin/lightbox";
@import "themes/plugin/notification";
@import "themes/plugin/pnotify";
@import "themes/plugin/bar-rating";
@import "themes/plugin/rangeslider";
@import "themes/plugin/daterange";
@import "themes/plugin/tags-input";
@import "themes/plugin/maxlength";
@import "themes/plugin/wizard";
@import "themes/plugin/select2";
@import "themes/plugin/data-tables";

// Charts
@import "themes/plugin/highcharts";
@import "themes/plugin/peitychart";


// pages
@import "themes/pages/authentication";
@import "themes/pages/maintaince";
@import "themes/pages/pages";
@import "themes/pages/help-desk";
@import "themes/pages/fullcalender";
@import "themes/pages/icon-lauouts";
@import "themes/pages/user";
@import "themes/pages/task-board";
@import "themes/pages/masonry-gallery";

//phetsarath fonts
@import "fonts/Phetsarathwebfonts/style";
@import "fonts/Phetsarath OT/style";

// custom
.cursor-pointer{
  cursor: pointer;
}
@import "themes/custom";
.data-table-extensions {
  padding: 0.2rem 0.2rem !important;
}
.swal2-container{
  z-index: 1234 !important;
}
body.svg #lightboxBackdrop img{
  min-height: calc(100vh - 150px);
}
.pcoded-navbar .navbar-content, .pcoded-navbar .navbar-wrapper{
  // padding-bottom: 100px;
}
a.disabled {
  pointer-events: none;
  cursor: default;
}

body{
  padding: 0 !important;
}
.lo-family, .jodit.lo-family,#uncontrolled-tab-example-tab-lo{
  font-family: 'Phetsarath OT', sans-serif !important;
}

.translation-transform .arrow{
  font-size: 10px;
  text-align:center;
  margin: 2px;

}
.translation-transform  .arrow div button{
  margin: 4px
}

#lang_format{
  //margin-left: -10px;
  Button{
    padding: 2px;
line-height: 1;
    i {
      font-size: 11px;
    }

  }

}

@media (min-width: 1360px)  {
  #lang_format {
    Button {
      padding: 6px;

      i {
        font-size: 12px;
      }



    }
  }
}